import React, { Component } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { camelCase, isBrowser, replaceAll } from '../../utils/helpers';
import './CompileQuote.scss'
import * as QuoteLayout from './QuoteLayout';
import { QuoteNavigation } from './QuoteNavigation';
import ls from 'local-storage'
import { FaCaretLeft } from 'react-icons/fa';
import {Image} from '../Image';

export const getCategoryBySlug = (slug, from) => {
  return from.edges.filter(category => category.node.slug === slug)
}

const QuoteComponent = ({ activeQuoteType, item, setQuoteType, categories, products, markUp, productPass }) => {
  const { name, slug, description, acf } = item
  const ComponentNamePass = QuoteLayout[camelCase(name)];
  if (!ComponentNamePass) {
    return (
      <div className="type">The Quote component <strong>"{camelCase(name)}"</strong> is missing. <span>&#128540;</span></div>
    )
  }else{
    return (
      <div className={`type ${slug}${activeQuoteType === slug ? ' active' : !activeQuoteType ? '' : ' inactive' }`}>
        <ComponentNamePass
          parent={item}
          categories={categories}
          products={products}
          markUp={markUp}
          activeQuoteType={activeQuoteType}
          setQuoteType={setQuoteType}
          productPass={productPass}
        />
      </div>

    );
  }

};

class Compile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuoteType: null
    }
  }

  componentDidMount() {
    if (isBrowser) {
      const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
      const type = urlParams && urlParams.get('type') && urlParams.get('type') !== 'undefined' && urlParams.get('type');
      const permitted = ['usb', 'production', 'transfers']
      const productPass = urlParams?.get('product')
      //'permitted:',permitted)
      //console.log('type:',type)
      //console.log('permitted:',permitted.includes(type))
      if (type && permitted.includes(type)) { this.setState({ activeQuoteType:type, passProductId: productPass }) }
      const showPrice = urlParams && urlParams.get('showPrice');
      if (showPrice == 'true') { ls.set('showPrice', true) }
    }
  }

  setQuoteType = (e, thisId) => {
    e.preventDefault()
    const { activeQuoteType } = this.state
    const updateQuoteType = thisId === activeQuoteType ? null : thisId
    this.setState({
      activeQuoteType: updateQuoteType
    })
    if (updateQuoteType === null) {
      navigate(`/quote/`)
    } else {
      navigate(`/quote/?type=${thisId}`)
    }
    
  }

  render() {
    const { data } = this.props
    const { activeQuoteType, passProductId } = this.state
    //console.log('activeQuoteType:',activeQuoteType)
    const { products, categories, markUp } = data;
    const includeGroup = [78, 94, 76, 101]
    const parentCategories = categories.edges.filter(a => a.node.wordpress_parent === 0 && includeGroup.includes(a.node.wordpress_id))
    const activeCategory = activeQuoteType ? categories.edges.filter(a => a.node.slug === activeQuoteType)  : null
    return (
      <section className="quote-build">
        
        <QuoteNavigation activeStep={activeQuoteType ? 1 : 0} setQuoteType={this.setQuoteType} />
        
        {!activeQuoteType && <div className="tile-title"><span className="title"><strong>{!activeQuoteType ? 'Select your service' : activeCategory[0].node.name}</strong></span></div>}

        {!activeQuoteType &&
          <div className="quote-types">
          {parentCategories && parentCategories.map(({node}, index) => 
            <div key={index} className={`service ${node.slug}${activeQuoteType === node.slug ? ' active' : !activeQuoteType ? '' : ' inactive' }`}>
              <div className="type-title" onClick={e => this.setQuoteType(e, node.slug)}>
                {node.acf.featured_image && <div className="icon"><Image src={node.acf.featured_image} position="relative"/></div>}
                <h3 className="title">{node.acf.menuTitle ? node.acf.menuTitle.replaceAll("/", " / ​") : node.name}</h3>
                <button className="action">Get Started</button>
              </div>  
            </div> 
          )}
          </div>
        }

        {activeQuoteType && 
          <QuoteComponent
            activeQuoteType={activeQuoteType}
            item={activeCategory[0].node}
            setQuoteType={this.setQuoteType}
            products={products.edges}
            categories={categories.edges}
            markUp={markUp.edges}
            productPass={passProductId}
          />
        }

      </section>
    )
  }
}

export const CompileQuote = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          products: allWordpressWpProducts(sort: {order: ASC, fields: ord}) {
            edges {
              node {
                wordpress_id
                ord
                mark_up
                product_categories
                title
                slug
                link
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400 ) {
                        ... GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                acf {
                  displayTitle
                  productPrice {
                    price
                    qty
                  }
                  minQty
                  maxQty
                  weight
                  supplierName
                  supplierId
                  chip
                  relatedCategory
                  relatedProduct
                }
              }
            }
          }
          categories: allWordpressWpProductCategories(sort: {order: ASC, fields: ord}) {
            edges {
              node {
                name
                slug
                count
                wordpress_id
                wordpress_parent
                description
                acf {
                  displayTitle
                  menuTitle
                  featured_image {
                    localFile {
                      publicURL
                    }
                  }
                  minQty
                  maxQty
                }
              }
            }
          }
          markUp: allWordpressWpMarkUp {
            edges {
              node {
                name
                slug
                acf {
                  markUpNsw {
                    percent
                    qty
                  }
                  markUpWa {
                    percent
                    qty
                  }
                }
                wordpress_parent
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => <Compile {...props} data={data} />}
    />
  );
}
