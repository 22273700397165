import React, { Component } from "react"
import { FaCaretLeft } from 'react-icons/fa';
import ls from 'local-storage'
import { navigate } from 'gatsby';

class ThisQuote extends Component {
  state = {
    quote: ls.get('quote') ? ls.get('quote') : [],
    note: null,
  }

  handleSubmit = ( e ) => {
    e.preventDefault()
    const {
      quote,
      note,
    } = this.state
    const quoteProductArray = {
      note: note,
      customQuote: true,
      quoteType: 'custom'
    }
    const newArray = quote.concat(quoteProductArray)
    ls.set('quote', newArray)
    //this.setState({ loading: false, submitted: true, quote: newArray, hasSubmitted: true });
    navigate('/request-a-quote/')
  }

  onChangeHandler = (e) => {
    let newState = this.state
    newState[e.target.name] = e.target.value
    this.setState(newState)
  }
  render() {
    const { parent, setQuoteType, activeQuoteType } = this.props
    const { note } = this.state
    return (
      <div className="content custom">

        <div className="tile-title">
          <span className="title"><strong>Custom quote request</strong></span>
          <button className="back-button" onClick={event => setQuoteType(event, null)}><FaCaretLeft/> Change Quote Type</button>
        </div>

        <div className="form">
          <div className="form-inner">


              <h4 className="group-title">Describe what you would like to quote on</h4>
            
              <div className={`note${note ? ' active' : '' }`}>
                <label htmlFor="note">Add a note</label>
                <textarea name="note" id="note" placeholder="Add a note" onChange={e => this.onChangeHandler(e)} value={note} />
              </div>

              <div className="add-to-cart">
                <div className="inner">
                  <button className={`add-to-quote${note ? '' : ' disable'}`} onClick={e => this.handleSubmit(e)} disabled={note ? false : true}>Add to quote</button>
                </div>
              </div>

          </div>
        </div>
      </div>
    )
  }
}

export const CustomProduct = (props) => {
  return <ThisQuote {...props}/>
}