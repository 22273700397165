import React, { Component } from 'react';
import GatsbyLink from '../../../GatsbyLink';
import './RelatedPass.scss';
import Carousel from '../../../Carousel';
import PostListItem from '../../PostListItem'
import ProductListItem from '../../../Products/ProductListItem'
import LazyLoad from 'react-lazy-load'

class ThisSlider extends Component {
  render () {
    const { posts, siteMetadata, pathPrefix, showAuthor, items = 3, showRecent, exclude } = this.props;
    const settings = {
      container: `related-carousel-${pathPrefix}${showRecent ? '-recent' : ''}`,
      nav: true,
      mouseDrag: true,
      controls: true,
      loop: false,
      gutter: 20,
      slideBy: 1,
      loop:pathPrefix === 'projects' ? true : false,
      speed: 600,
      lazyload: true,
      //controlsContainer: '.related-nav',
      responsive: {
        1600: {
          items: items,
        },
        1024: {
          items: items,
        },
        640: {
          items: items,
        },
        420: {
          items: 1,
        }
      }
    };

    return (
      <div className="slider">
        <Carousel settings={settings}>
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' && exclude.includes(post.node.wordpress_id) ? null :
              pathPrefix === 'products' ?
                 <ProductListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                :
                <PostListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} lazy={false}/>
          )}
        </Carousel>
      </div>
    )
  }
}

class ListView extends Component {
  render () {
    const { posts, siteMetadata, showAuthor, pathPrefix, exclude } = this.props;
    return (
      <div className="slider list-view">
          {posts && posts.map((post, slide_count) => post.node.slug !== 'gatsby' && !exclude.includes(post.node.wordpress_is) &&

            pathPrefix === 'products' ?
            <ProductListItem key={slide_count} data={post.node} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
            :
            <PostListItem
              key={slide_count}
              data={post.node}
              siteMetadata={siteMetadata}
              pathPrefix={pathPrefix}
              showAuthor={showAuthor}
            />
          )
        }
      </div>
    )
  }
}

export const RelatedPass = (props) => {
  const {
    posts,
    title = 'Related Posts',
    listView,
    contentType = 'blog',
    action,
    siteSettings = {},
    items,
    limit = 6,
    showRecent,
    exclude = []
  } = props;

  const { showAuthor = false } = siteSettings
  const pathPrefix = contentType;
  const returnPosts = posts
  if (!returnPosts || returnPosts.length <= 0) return null
  return (
    <LazyLoad className={`post-feed ${contentType}-list${listView ? ' list-view' : ''}`} offsetBottom={100} debounce={false}>
        <div className="wrap">
            <div className="intro">
              {title && <span className="title section-title h3" dangerouslySetInnerHTML={{ __html: title }} />}
              {action && <GatsbyLink to={action.url} aria-label={action.title} className="link">{action.title}</GatsbyLink> }
            </div>

            {!listView && returnPosts && <ThisSlider posts={returnPosts.slice(0, limit)} pathPrefix={pathPrefix} showAuthor={showAuthor} items={items} showRecent={showRecent} exclude={exclude} />}

            {listView && <ListView posts={returnPosts} showAuthor={showAuthor} pathPrefix={pathPrefix} exclude={exclude} />}
        </div>
    </LazyLoad>

  )
}
