import React, { Component } from "react"
import { navigate } from 'gatsby';
import { Image } from '../../../Image';
import { slugify, numberToMoney, truncated, isBrowser } from '../../../../utils/helpers';
import ls from 'local-storage'
import { filterByQty, priceByQty } from '../../helpers'
import { AdminPrice } from '../../AdminPrice'
import { FaCaretLeft } from 'react-icons/fa';

const Option = (props) => {
  const {setThis, val, product, title, className = null } = props
  const thisTitle = product.title === 'NA' ? title : product.acf.displayTitle ? product.acf.displayTitle : product.title
  return (
    <div className={`${className} ${val ? 'select' : 'product' }`} onClick={e => setThis(e, val)}>
      <div className="image"><Image src={product.featured_media}/></div>
      <div className={val ? 'select-label' : 'label'}>{thisTitle}</div>
    </div>
  )
}

export class Production extends Component {
  state = {
    quote: ls.get('quote') ? ls.get('quote') : [],
    showPrice: ls.get('showPrice') ? true : false,
    qty: null,
    qtySet: null,
    quoteType: null,
    productGroup: null,
    product: null,
    packaging: null,
    packagingCheck: null,
    extrasCheck: null,
    extrasOne: null,
    extrasTwo: null,
    usbCapacity: '1GB',
    usbPrinting: 'Yes',
    productFilter: null,
    note: '',
    customQuote: null,
    totalNsw: 0,
    totalWa: 0
  }

  handleSubmit = ( e ) => {
    e.preventDefault()
    const {
      quote,
      qty,
      product,
      packaging,
      extrasOne,
      extrasTwo,
      usbCapacity,
      usbPrinting,
      usd,
      note,
      customQuote,
      totalNsw,
      totalWa,
      quoteType
    } = this.state
    const quoteProductArray = {
      productId: product ? product.wordpress_id : null,
      packaging: packaging ? packaging.wordpress_id : null,
      extrasOne: extrasOne ? extrasOne.wordpress_id : null,
      extrasTwo: extrasTwo ? extrasTwo.wordpress_id : null,
      usbPrinting: usbPrinting,
      usbCapacity: usbCapacity,
      qty: qty,
      usd: usd,
      note: note,
      customQuote: customQuote,
      totalWa: totalWa,
      totalWaIncGst: totalWa * 1.1,
      totalNsw: totalNsw,
      totalNswIncGst: totalWa * 1.1,
      quoteType: quoteType
    }
    const newArray = quote.concat(quoteProductArray)
    ls.set('quote', newArray)
    //this.setState({ loading: false, submitted: true, quote: newArray, hasSubmitted: true });
    navigate('/request-a-quote/')
  }

  setProductGroup = (event, a, type) => {
    this.setState({
      productGroup: a,
      quoteType: type,
      product: null,
      packaging: null,
      extrasOne: null,
      extrasTwo: null,
      //qty: a ? a.acf.minQty : null,
      qty: '',
      qtySet: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setProduct = (event, a) => {
    this.setState({
      product: a,
      packaging: null,
      extrasOne: null,
      extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })

  }

  setPackaging = (event, a) => {
    this.setState({
      packaging: a,
      extrasOne: null,
      extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setExtras = (event, a) => {
    this.setState({
      extrasOne: a,
      extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setExtrasTwo = (event, a) => {
    this.setState({
      extrasTwo: a,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setQty = (event, a) => {
    this.setState({
      qtySet: true
    }, () => {
      this.calcPrice()
    })
  }

  onChangeHandler = (e) => {
    let newState = this.state
    if ( e.target.type === 'checkbox' ) {
      //Toggle value on off
      newState[e.target.name] === e.target.value ? newState[e.target.name] = '' : newState[e.target.name] = e.target.value
    } else {
      newState[e.target.name] = e.target.value
    }
    if (e.target.name === 'qty') {
      newState['product'] = null
      newState['packaging'] = null
      newState['extrasOne'] = null
      newState['extrasTwo'] = null
      newState['productFilter'] = null
      newState['qtySet'] = false
    }
    
    this.setState(newState, () => {
      this.calcPrice()
    })
  }

  calcPrice() {
    const {
      products,
      markUp,
    } = this.props
    const {
      qty,
      qtySet,
      productGroup,
      product,
      packaging,
      quoteType,
      extrasOne,
      extrasTwo,
      usd
    } = this.state

    let markupWaPercent = 1
    let markupNswPercent = 1
    let unitPrice = 0

    //Product
    const lastProductPriceByQty = product && qty && product.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    const productPriceByQty = lastProductPriceByQty && lastProductPriceByQty.price
    if (productPriceByQty) {
      unitPrice = Number(productPriceByQty)
    }

    //Longrun Blu-ray
    if (productGroup) {
      if ( qty >= 500 && productGroup.slug === 'blu-ray-std' || qty >= 500 && productGroup.slug === 'blu-ray-dual-layer' ) {
        //Flat stamper cost
        const stamperTypeId = productGroup.slug === 'blu-ray-dual-layer' ? 706 : 709 // Get the ID from the
        const stamperProduct = products && products.filter(a => a.node.wordpress_id === stamperTypeId)[0].node
        const lastStamperProduct = stamperProduct && stamperProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastStamperProduct) {
          unitPrice = unitPrice + Number(lastStamperProduct.price) / qty
        }

        //Flat copy protection cost
        const copyProtectProduct = products && products.filter(a => a.node.wordpress_id === 707)[0].node
        const lastCopyProtectProduct = copyProtectProduct && copyProtectProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastStamperProduct) {
          unitPrice = unitPrice + Number(lastCopyProtectProduct.price) / qty
        }

        //Flat Blu-ray CheckDisc cost
        const checkDiscProduct = products && products.filter(a => a.node.wordpress_id === 708)[0].node
        const lastCheckDiscProduct = checkDiscProduct && checkDiscProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastCheckDiscProduct) {
          unitPrice = unitPrice + Number(lastCheckDiscProduct.price) / qty
        }
      }
    }


    //Packaging
    const packagingPriceByQty = packaging && qty && packaging.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (packagingPriceByQty) {
      if (isNaN(packagingPriceByQty.price)) {
        this.setState({customQuote: true})
      } else {
        unitPrice = unitPrice + Number(packagingPriceByQty.price)
      }
    }

    //Extra One
    const extraPriceByQty = extrasOne && qty && extrasOne.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (extraPriceByQty) {
      unitPrice = unitPrice + Number(extraPriceByQty.price)
    }

    //Extra Two
    const extraTwoPriceByQty = extrasTwo && qty && extrasTwo.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (extraTwoPriceByQty) {
      unitPrice = unitPrice + Number(extraTwoPriceByQty.price)
    }

    //Mark up
    if (product && product.mark_up && product.mark_up[0]) {
      const markupGroup = markUp.filter(a => a.node.wordpress_id === product.mark_up[0])
      const markupWaPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpWa.filter(a => priceByQty(a, qty)).pop()
      const markupNswPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpNsw.filter(a => priceByQty(a, qty)).pop()

      markupWaPercent = markupWaPass ? markupWaPass.percent : markupWaPercent
      markupNswPercent = markupNswPass ? markupNswPass.percent : markupNswPercent
    }

    const totalWa = quoteType === 'usb' ?
      ( ( unitPrice * qty ) * markupWaPercent ) * usd :
      ( unitPrice * qty ) * markupWaPercent
    const totalNsw = quoteType === 'usb' ?
      ( ( unitPrice * qty ) * markupNswPercent ) * usd :
      ( unitPrice * qty ) * markupNswPercent

    this.setState({
      totalWa: totalWa,
      totalNsw: totalNsw
    })
  }

  render () {

    const {
      products,
      categories,
      parent,
      setQuoteType
    } = this.props

    const {
      qty,
      qtySet,
      productGroup,
      product,
      packaging,
      packagingCheck,
      extrasCheck,
      extrasOne,
      extrasTwo,
      customQuote,
      totalWa,
      totalNsw,
      showPrice,
      note
    } = this.state

    if ( productGroup ) { //After product group has been selected

      const thisProducts = products.filter(a => a.node.product_categories.includes(productGroup.wordpress_id))

      const filterProductsQty = qty && thisProducts && thisProducts.filter(a => filterByQty(a, qty))

      const noProduct = products.filter(a => a.node.wordpress_id === 358)

      let extrasOneMessage = <p>No options available</p>;

      return (
        <div className="content production form">

          <div className="tile-title">
              <span className="title">{productGroup.acf.displayTitle ? productGroup.acf.displayTitle : productGroup.name} <strong>Production</strong></span>
              <button className="back-button" onClick={event => this.setProductGroup(event, null)}><FaCaretLeft/> Change Production Type</button>
          </div>
          
          <div className="form-inner">

            <h4 className="group-title">Select your quantity</h4>
            
            <div className="qty active inline">
              <label htmlFor="qty">Qty</label>
              <input
                name="qty"
                id="qty"
                placeholder="Qty"
                type="number"
                pattern="[0-9]*"
                //min={productGroup.acf.minQty}
                max={productGroup.acf.maxQty}
                value={qty}
                onChange={e => this.onChangeHandler(e)}
              />
            </div>


            <>
              <h4 className="group-title">Select media type</h4>
              {!qty && <p>Please specify your quantity</p>}
              {qty &&
              <div className="product-list">
                <div className={`inner${product ? ' set' : ''}`}>

                  {!product && filterProductsQty && filterProductsQty.map((a, index) => ( //List disc products
                    <Option key={index} product={a.node} setThis={this.setProduct} val={a.node} className="production" />
                  ))}

                  {product &&
                    <Option product={product} setThis={this.setProduct} val={null} className="production" />
                  }

                </div>
              </div>
              }
            </>
 

            { //Packaging that determines what printing parts are available
            <>
              <h4 className="group-title">Select packaging
                <input
                  type="checkbox"
                  id="packagingCheck"
                  name="packagingCheck"
                  onChange={() => this.setState({packaging: null, packagingCheck: packagingCheck ? '' : 'Yes'})}
                  value="Yes"
                  checked={packagingCheck}
                />
              </h4>
              
              {packagingCheck && product && 
              <div className="product-list options">
                <div className={`inner${packaging ? ' set' : ''}`}>

                  {!packaging && product && product.acf && product.acf.relatedCategory && product.acf.relatedCategory.map((a, index) => {
                    const relatedCategories = products.filter(b => b.node.product_categories.includes(a) && filterByQty(b, qty))
                    return (
                      relatedCategories && relatedCategories.map((c, index) => (
                        <Option key={index} product={c.node} setThis={this.setPackaging} val={c.node} />
                      ))
                    )
                  })}

                  {packaging &&
                    <Option product={packaging} setThis={this.setPackaging} val={null} title='No Packaging' />
                  }

                </div>
              </div>
              }
              {packagingCheck && !product && 
                <p>Please select your media type</p>
              }
            </>
            }

            {packaging &&//Options One
            <>
              <h4 className="group-title">Select packaging options
              <input
                type="checkbox"
                id="extrasCheck"
                name="extrasCheck"
                onChange={e => this.onChangeHandler(e)}
                onChange={() => this.setState({extrasOne: null, extrasCheck: extrasCheck ? '' : 'Yes'})}
                value="Yes"
                checked={extrasCheck}
              />
              </h4>
              {extrasCheck && 
              <div className="product-list options one">
                <div className={`inner${extrasOne ? ' set' : ''}`}>

                  {!extrasOne && packaging?.acf?.relatedCategory.map(a => {
                    const relatedCategories = products.filter(b => b.node.product_categories.includes(a) && filterByQty(b, qty))
                    if ( relatedCategories  ) extrasOneMessage = ''
                    return (
                      relatedCategories && relatedCategories.map((c, index) => (
                          <Option key={index} product={c.node} setThis={this.setExtras} val={c.node} />
                      ))
                    )
                  })}

                  {!extrasOne && packaging?.acf?.relatedProduct.map(a => {
                    const relatedProducts = products.filter(b => b.node.wordpress_id === a && filterByQty(b, qty))
                    if ( relatedProducts  ) extrasOneMessage = ''
                    return (
                      relatedProducts && relatedProducts.map((c, index) => (
                          <Option key={index} product={c.node} setThis={this.setExtras} val={c.node} />
                      ))
                    )
                  })}

                  {extrasOne &&
                    <Option product={extrasOne} setThis={this.setExtras} val={null} title='No Option One' />
                  }

                  {!extrasOne && extrasOneMessage}
                </div>
              </div>
              }
            </>
            }

            {extrasOne &&
            <>
              <div className="product-list options two">
                <div className={`inner${extrasTwo ? ' set' : ''}`}>

                  {extrasOne && !extrasTwo && packaging && packaging.acf && packaging.acf.relatedCategory && packaging.acf.relatedCategory.map((a, index) => {
                    const relatedCategories = products.filter(b => b.node.product_categories.includes(a) && filterByQty(b, qty))
                    return (
                      relatedCategories && relatedCategories.map((c, index) => (
                          <Option key={index} product={c.node} setThis={this.setExtrasTwo} val={c.node} />
                      ))
                    )
                  })}

                  {extrasOne && !extrasTwo && packaging && packaging.acf && packaging.acf.relatedProduct && packaging.acf.relatedProduct.map((a, index) => {
                    const relatedProducts = products.filter(b => b.node.wordpress_id === a && filterByQty(b, qty))
                    return (
                      relatedProducts && relatedProducts.map((c, index) => (
                          <Option key={index} product={c.node} setThis={this.setExtrasTwo} val={c.node} />
                      ))
                    )
                  })}

                  {!extrasTwo && extrasOne && noProduct && noProduct.map((a, index) => (
                      <Option key={index} product={a.node} setThis={this.setExtrasTwo} val={a.node} title='No Option Two' />
                  ))}

                  {extrasTwo && //Active option
                    <Option product={extrasTwo} setThis={this.setExtrasTwo} val={null} title='No Option Two' />
                  }

                </div>
              </div>
            </>
            }



            <>
              <h4 className="group-title">Add product instructions / notes</h4>
              <div className={`note${note ? ' active' : '' }`}>
                <label htmlFor="note">Add a note</label>
                <textarea name="note" id="note" placeholder="Add a note" onChange={e => this.onChangeHandler(e)} value={note} />
              </div>

              <div className="add-to-cart">
                <div className="inner">
                  <button className={`add-to-quote${product ? '' : ' disable' }`} onClick={e => this.handleSubmit(e)} disabled={product ? false : true }>Add to quote</button>
                </div>
              </div>
            </>


          </div>

          {showPrice && <AdminPrice totalWa={totalWa}  totalNsw={totalNsw} qty={qty} customQuote={customQuote} />}

        </div>
      )
    }

    const parentCategories = categories.filter(a => a.node.wordpress_parent === parent.wordpress_id) //Top level options

    return (
      <div className="content production">

        <div className="tile-title">
          <span className="title"><strong>Select production type</strong></span>
          <button className="back-button" onClick={event => setQuoteType(event, null)}><FaCaretLeft/> Change Quote Type</button>
        </div>

        <div className="groups">
          {parentCategories && parentCategories.map((a, index)=> {
            const subCategories = categories && categories.filter(b => b.node.wordpress_parent === a.node.wordpress_id)
            return (
              <div className="product-group" key={index}>
                <div className="wrap">
                <div className="group"><span>{a.node.acf && a.node.acf.displayTitle ? a.node.acf.displayTitle : a.node.name}</span></div>
                  {subCategories && subCategories.map(({node}, index)=> 
                    <div className="product" key={index} onClick={event => this.setProductGroup(event, node, 'disc')}>
                      <div className="in">
                          <strong>{node.name}</strong>{node.description && <div className="hover">{node.description}</div>}
                          {node.acf.featured_image && <div className="icon"><Image src={node.acf.featured_image}/></div>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
