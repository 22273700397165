import React from "react"
import { numberToMoney } from './helpers';

export const AdminPrice = ({totalWa, totalNsw, qty, customQuote}) => {
    if (totalWa === 0) return null
    return (
        <div className="total">
        <div className="wrap">
        {customQuote ?
            <span>A price for this product is unavailable online.</span>
        :
            <div>
                {(typeof qty === 'object') ? `` : `Unit Price (ex GST) ${numberToMoney(totalWa/parseInt(qty))} - `}Total (ex GST): {numberToMoney(totalWa)} - <strong>Total (inc GST): {numberToMoney(totalWa * 1.1)}</strong>
            </div>
        }
        </div>
        </div>
    )
}