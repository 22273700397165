import React, { Component } from "react"
import { navigate } from 'gatsby';
import { Image } from '../../../Image';
import { slugify, numberToMoney, truncated, isBrowser } from '../../../../utils/helpers';
import fetch from 'isomorphic-fetch';
import ls from 'local-storage'
import { FaCaretLeft } from 'react-icons/fa';
import { filterByQty, priceByQty } from '../../helpers'
import { AdminPrice } from '../../AdminPrice'

const Option = (props) => {
  const {setThis, val, product, title} = props
  const thisTitle = product.title === 'NA' ? title : product.title
  return (
    <div className={val ? 'select' : 'product' } onClick={e => setThis(e, val)}>
      <div className="image"><Image src={product.featured_media}/></div>
      <div className={val ? 'select-label' : 'label'}>{thisTitle}</div>
    </div>
  )
}

const Placelist = () => {
  return (
    <>
    {[...Array(21)].map((x, i) =>
      <div className="placehold">
        <div className="image" /><div className="select-label"/>
      </div>
    )}
    </>
  )
  
}

class ThisPromotionalUsb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: ls.get('quote') ? ls.get('quote') : [],
      showPrice: ls.get('showPrice') ? true : false,
      qty: 50,
      qtySet: null,
      quoteType: 'usb',
      productGroup: this.props.parent,
      product: null,
      packaging: null,
      extrasOne: null,
      extrasTwo: null,
      usbCapacity: '1GB',
      usbPrinting: 'Yes',
      usd: 1,
      productFilter: null,
      note: '',
      customQuote: null,
      totalNsw: 0,
      totalWa: 0,
      packagingCheck: 'No',
      extrasCheck: 'No'
    }
  }

  async loadData() {
    const functionsStringUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/exchange/v2/rates`
    fetch(functionsStringUrl)
    .then(response => response.json())
    .then( data => {
       this.setState({ usd: data.rates.AUD });
    })
    .catch(error => {
       console.error('Excahnge Error', error)
    });
  }

  componentDidMount() {
    this.loadData();
    //Pass product id
    if (this.props?.productPass) {
      const checkProduct = this.props.products.filter(a => a.node.wordpress_id === parseInt(this.props.productPass))
      if (checkProduct[0]?.node) {
        this.setState({product: checkProduct[0]?.node}) 
      }
    }
  }


  handleSubmit = ( e ) => {
    e.preventDefault()
    const {
      quote,
      qty,
      product,
      packaging,
      extrasOne,
      extrasTwo,
      usbCapacity,
      usbPrinting,
      usd,
      note,
      customQuote,
      totalNsw,
      totalWa,
      quoteType
    } = this.state
    const quoteProductArray = {
      productId: product ? product.wordpress_id : null,
      packaging: packaging ? packaging.wordpress_id : null,
      extrasOne: extrasOne ? extrasOne.wordpress_id : null,
      extrasTwo: extrasTwo ? extrasTwo.wordpress_id : null,
      usbPrinting: usbPrinting,
      usbCapacity: usbCapacity,
      qty: qty,
      usd: usd,
      note: note,
      customQuote: customQuote,
      totalWa: totalWa,
      totalWaIncGst: totalWa * 1.1,
      totalNsw: totalNsw,
      totalNswIncGst: totalWa * 1.1,
      quoteType: quoteType
    }
    const newArray = quote.concat(quoteProductArray)
    ls.set('quote', newArray)
    //this.setState({ loading: false, submitted: true, quote: newArray, hasSubmitted: true });
    navigate('/request-a-quote/')
  }

  setProductGroup = (event, a, type) => {
    this.setState({
      productGroup: a,
      quoteType: type,
      product: null,
      packaging: null,
      extrasOne: null,
      extrasTwo: null,
      //qty: a ? a.acf.minQty : null,
      qty: '',
      qtySet: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setProduct = (event, a) => {
    this.setState({
      product: a,
      packaging: null,
      packagingCheck: 'No',
      extrasOne: null,
      extrasCheck:'No',
      extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })

  }

  setPackaging = (event, a) => {
    this.setState({
      packaging: a,
      //extrasOne: null,
      //extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setExtras = (event, a) => {
    this.setState({
      extrasOne: a,
      extrasTwo: null,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setExtrasTwo = (event, a) => {
    this.setState({
      extrasTwo: a,
      productFilter: null
    }, () => {
      this.calcPrice()
    })
  }

  setQty = (event, a) => {
    this.setState({
      qtySet: true
    }, () => {
      this.calcPrice()
    })
  }

  onChangeHandler = (e) => {
    const oldState = this.state
    if ( e.target.type === 'checkbox' ) {
      //Toggle value on off
      oldState[e.target.name] === e.target.value ? oldState[e.target.name] = '' : oldState[e.target.name] = e.target.value
    } else {
      e.preventDefault()
      oldState[e.target.name] = e.target.value
    }
    this.setState(oldState, () => {
      this.calcPrice()
    })
  }

  calcPrice() {
    const {
      products,
      markUp,
    } = this.props
    const {
      qty,
      productGroup,
      product,
      packaging,
      quoteType,
      extrasOne,
      extrasTwo,
      usbCapacity,
      usbPrinting,
      usd
    } = this.state

    let markupWaPercent = 1
    let markupNswPercent = 1
    let shipping = 0
    let printing = 0
    let unitPrice = 0

    //Product
    const lastProductPriceByQty = product && qty && product.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    const productPriceByQty = lastProductPriceByQty && lastProductPriceByQty.price

    if (productPriceByQty) {
      unitPrice = Number(productPriceByQty)
    }

    //Longrun Blu-ray
    /*
    if (productGroup) {
      if ( qty >= 500 && productGroup.slug === 'blu-ray-std' || qty >= 500 && productGroup.slug === 'blu-ray-dual-layer' ) {
        //Flat stamper cost
        const stamperTypeId = productGroup.slug === 'blu-ray-dual-layer' ? 706 : 709 // Get the ID from the
        const stamperProduct = products && products.filter(a => a.node.wordpress_id === stamperTypeId)[0].node
        const lastStamperProduct = stamperProduct && stamperProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastStamperProduct) {
          unitPrice = unitPrice + Number(lastStamperProduct.price) / qty
        }

        //Flat copy protection cost
        const copyProtectProduct = products && products.filter(a => a.node.wordpress_id === 707)[0].node
        const lastCopyProtectProduct = copyProtectProduct && copyProtectProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastStamperProduct) {
          unitPrice = unitPrice + Number(lastCopyProtectProduct.price) / qty
        }

        //Flat Blu-ray CheckDisc cost
        const checkDiscProduct = products && products.filter(a => a.node.wordpress_id === 708)[0].node
        const lastCheckDiscProduct = checkDiscProduct && checkDiscProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        if (lastCheckDiscProduct) {
          unitPrice = unitPrice + Number(lastCheckDiscProduct.price) / qty
        }
      }
    }
    */

    //Packaging
    const packagingPriceByQty = packaging && qty && packaging.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (packagingPriceByQty) {
      if (isNaN(packagingPriceByQty.price)) {
        this.setState({customQuote: true})
      } else {
        unitPrice = unitPrice + Number(packagingPriceByQty.price)
      }
    }

    //Extra One
    const extraPriceByQty = extrasOne && qty && extrasOne.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (extraPriceByQty) {
      unitPrice = unitPrice + Number(extraPriceByQty.price)
    }

    //Extra Two
    const extraTwoPriceByQty = extrasTwo && qty && extrasTwo.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
    if (extraTwoPriceByQty) {
      unitPrice = unitPrice + Number(extraTwoPriceByQty.price)
    }

    //Mark up
    if (product && product.mark_up && product.mark_up[0]) {
      const markupGroup = markUp.filter(a => a.node.wordpress_id === product.mark_up[0])
      const markupWaPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpWa.filter(a => priceByQty(a, qty)).pop()
      const markupNswPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpNsw.filter(a => priceByQty(a, qty)).pop()

      markupWaPercent = markupWaPass ? markupWaPass.percent : markupWaPercent
      markupNswPercent = markupNswPass ? markupNswPass.percent : markupNswPercent
    }

    if (quoteType === 'usb') {

      //Shipping
      const productWeight = product && qty && product.acf.weight ? product.acf.weight : 0
      const shippingProduct = products && products.filter(a => a.node.wordpress_id === 701)[0].node
      const lastShippingProduct = shippingProduct && shippingProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
      //shipping = lastShippingProduct && lastShippingProduct.price / qt
      shipping = ( ( ( productWeight / 80 ) * qty ) + 70) / qty
      //shipping = ((rs0.fields("weight")/80)*q)+70
      if (usbPrinting) {
        //Printing
        const printingProduct = products && products.filter(a => a.node.wordpress_id === 607)[0].node
        const lastPrintingProduct = printingProduct && printingProduct.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
        printing = lastPrintingProduct && lastPrintingProduct.price / qty
      }
      //Chip
      const chipSize = usbCapacity && usbCapacity
      const chipType = product && qty && product.acf.chip
      const chipSlug = chipType && usbCapacity && slugify(`${chipType} ${usbCapacity}`)

      const lastChipPriceByQty = product && products && chipSlug && products.filter(a => a.node.slug === chipSlug)[0].node.acf.productPrice.filter(a => priceByQty(a, qty)).pop()
      const chipPriceByQty = lastChipPriceByQty && lastChipPriceByQty.price
      if (chipPriceByQty) {
        unitPrice = ( unitPrice + Number(chipPriceByQty) + Number(shipping) + Number(printing) )
      }

    }

    const totalWa = quoteType === 'usb' ?
      ( ( unitPrice * qty ) * markupWaPercent ) * usd :
      ( unitPrice * qty ) * markupWaPercent
    const totalNsw = quoteType === 'usb' ?
      ( ( unitPrice * qty ) * markupNswPercent ) * usd :
      ( unitPrice * qty ) * markupNswPercent

    this.setState({
      totalWa: totalWa,
      totalNsw: totalNsw
    })
  }

  render () {

    const {
      products,
      categories,
      setQuoteType
    } = this.props

    const {
      qty,
      productGroup,
      product,
      packaging,
      packagingCheck,
      extrasCheck,
      extrasOne,
      usbPrinting,
      productFilter,
      customQuote,
      totalWa,
      totalNsw,
      showPrice,
      note
    } = this.state

    const thisProductGroupCategories = categories.filter(a => a.node.wordpress_parent === productGroup.wordpress_id)
    const usbPackaging = products.filter(a => a.node.product_categories.includes(86))
    const usbExtras = products.filter(a => a.node.product_categories.includes(135))
    const usbCapacity = ['128MB', '256MB', '512MB', '1GB', '2GB', '4GB', '8GB', '16GB', '32GB']

    //const noProduct = products.filter(a => a.node.wordpress_id === 358)

    return (
      <div className="content usbs form">

        <div className="tile-title">
          <span className="title">{productGroup.acf.displayTitle ? productGroup.acf.displayTitle : productGroup.name} <strong>Production</strong></span>
          <button className="back-button" onClick={event => setQuoteType(event, null)}><FaCaretLeft/> Change Quote Type</button>
        </div>

        <div className="form-inner">

          <h4 className="group-title">Select your quantity and capacity</h4>

          <div className={`qty half${qty ? ' active' : ''}`}>
            <label htmlFor="qty">Qty (Min {productGroup.acf.minQty})</label>
            <input
              name="qty"
              id="qty"
              placeholder={`Qty (Min ${productGroup.acf.minQty})`}
              type="number"
              pattern="[0-9]*"
              min={productGroup.acf.minQty}
              max={productGroup.acf.maxQty}
              value={qty}
              onChange={e => this.onChangeHandler(e)}
            />
          </div>

          <div className="capacity active half">
            <label htmlFor="usbCapacity">Capacity</label>
            <select name="usbCapacity" id="usbCapacity" defaultValue="1GB" onChange={e => this.onChangeHandler(e)}>
              {usbCapacity.map((a, index) =>
                <option key={index} value={a}>{a}</option>
              )}
            </select>
          </div>
          
          <h4 className="group-title two-thirds">Do you require printing on the USB's
          <input
            type="checkbox"
            id="usbPrinting"
            name="usbPrinting"
            onChange={e => this.onChangeHandler(e)}
            value="Yes"
            checked={usbPrinting}
          />
          </h4>

          

          <>
          
          <h4 className="group-title two-thirds">Select your model</h4>
          <div className="usb-filter-wrap">
            
            
            
            {!product && 
              <div className={`usb-filter third${productFilter ? ' active' : ''}`}>
                <label htmlFor="productFilter">Filter by</label>
                <select name="productFilter" id="productFilter" onChange={e => this.onChangeHandler(e)}>
                  <option value="">Filter category</option>
                  {thisProductGroupCategories && thisProductGroupCategories.map((a, index) => (
                      <option key={index} value={a.node.wordpress_id}>{a.node.name}</option>
                  ))}
                </select>
              </div>
            }

            <div className="product-list usb-model">
              <div className={`inner${product ? ' set' : ''}`}>

                {!product && thisProductGroupCategories && thisProductGroupCategories.map((a, index) => { //List products within the category (USBs)
                  if (!productFilter || ( productFilter && a.node.wordpress_id == productFilter ) ) {
                    const productInThisCategory = products.filter(b => b.node.product_categories.includes(a.node.wordpress_id) && filterByQty(b, qty) )
                    return (
                      productInThisCategory && productInThisCategory.map((c, index) => (
                        <Option key={index} product={c.node} setThis={this.setProduct} val={c.node} />
                      ))
                    )
                  }
                }) 
              }

                {product && //Active product
                  <Option product={product} setThis={this.setProduct} val={null} />
                }

              </div>
            </div>
          </div>
          </>

          <h4 className="group-title">Do you require packaging</h4>
          <div className="radio">
            <label for="Yes" onClick={() => this.setState({packaging: null, packagingCheck: 'Yes'})}>
              <input
                type="radio"
                id="packagingCheck"
                name="packagingCheck"
                value="Yes"
                checked={packagingCheck === 'Yes' ? true : false}
              />Yes</label>
            
            <label for="No" onClick={() => this.setState({packaging: null, packagingCheck: 'No'})}>
            <input
                type="radio"
                id="packagingCheck"
                name="packagingCheck"
                value="No"
                checked={packagingCheck === 'No' ? true : false}
            />No</label>
          </div>

          {!product && packagingCheck === 'Yes' && <p>Please selet a USB model first</p>}
          
          {packagingCheck === 'Yes' && product &&
          <div className="product-list packaging">
            <div className={`inner${packaging ? ' set' : ''}`}>

              {!packaging && product && product.acf && product.acf.relatedCategory && product.acf.relatedCategory.map((a, index) => {
                const relatedCategories = products.filter(b => b.node.product_categories.includes(a) && filterByQty(b, qty))
                return (
                  relatedCategories ? relatedCategories.map((c, index) => (
                    <Option key={index} product={c.node} setThis={this.setPackaging} val={c.node} />
                  ) ) : <Placelist/> 
                )
              })}

              {product && !packaging && usbPackaging && usbPackaging.reverse().map((a, index) => 
                  <Option key={index} product={a.node} setThis={this.setPackaging} val={a.node} />
              )}

              {packaging && //Active product
                <Option product={packaging} setThis={this.setPackaging} val={null} title='No Packaging' />
              } 

            </div>
          </div>
          }
          

          <h4 className="group-title">Do you require an accessory</h4>
          <div className="radio">
            <label for="Yes" onClick={() => this.setState({extrasOne: null, extrasCheck: 'Yes'})}>
            <input
              type="radio"
              id="extrasCheck"
              name="extrasCheck"
              value="Yes"
              checked={extrasCheck === 'Yes' ? true : false}
            />Yes</label>

            <label for="No" onClick={() => this.setState({extrasOne: null, extrasCheck: 'No'})}>
            <input
              type="radio"
              id="extrasCheck"
              name="extrasCheck"  
              value="No"
              checked={extrasCheck === 'No' ? true : false}
            />No</label>
          </div>

          {!product && extrasCheck === 'Yes' && <p>Please selet a USB model first</p>}

          {extrasCheck === 'Yes' && product &&
            <div className="product-list extras">
              <div className={`inner${extrasOne ? ' set' : ''}`}>
              
                {!extrasOne && packaging && packaging.acf && packaging.acf.relatedCategory && packaging.acf.relatedCategory.map((a, index) => {
                  const relatedCategories = products.filter(b => b.node.product_categories.includes(a) && filterByQty(b, qty))
                  return (
                    relatedCategories && relatedCategories.map((c, index) => (
                      <Option key={index} product={c.node} setThis={this.setExtras} val={c.node} />
                    ))
                  )
                })}

                {!extrasOne && packaging && packaging.acf && packaging.acf.relatedProduct && packaging.acf.relatedProduct.map((a, index) => {
                  const relatedProducts = products.filter(b => b.node.wordpress_id === a && filterByQty(b, qty))
                  return (
                    relatedProducts && relatedProducts.map((c, index) => (
                      <Option key={index} product={c.node} setThis={this.setExtras} val={c.node} />
                    ))
                  )
                })}

                {product && !extrasOne && usbExtras && usbExtras.reverse().map((a, index) => (
                  <Option key={index} product={a.node} setThis={this.setExtras} val={a.node} />
                ))}

                {extrasOne && //Active package
                  <Option product={extrasOne} setThis={this.setExtras} val={null} title='No Option One' />
                }

              </div>
            </div>
          }

          
            <>
              <h4 className="group-title">Add product instructions / notes</h4>
              <div className={`note${note ? ' active' : '' }`}>
                <label htmlFor="note">Add a note</label>
                <textarea name="note" id="note" placeholder="Add a note" onChange={e => this.onChangeHandler(e)} value={note} />
              </div>

              <div className="add-to-cart">
                <div className="inner">
                  <button className={`add-to-quote${!product ? ' disable' : ''}`} onClick={e => this.handleSubmit(e)} disabled={!product ? true : false}>Add to quote</button>
                </div>
              </div>
            </>

        </div>

        {showPrice && <AdminPrice totalWa={totalWa}  totalNsw={totalNsw} qty={qty} customQuote={customQuote} />}
        
      </div>
    )
  }
}

export const PromotionalUsb = (props) => {
    return <ThisPromotionalUsb {...props}/>
}