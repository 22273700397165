import React from 'react'
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent';
import { ImagePass } from '../../Image/ImagePass';
import './ProductListItem.scss'
import { decodeEntities } from '../../../utils/helpers';

export default class ProductListItem extends React.Component {
  render () {
    const { data } = this.props;
    const { excerpt, title, path, featured_media, acf, linkText = 'View details', count = null, product_categories } = data
    const image_to_use = acf?.featured_image ? acf.featured_image : featured_media
    const special = product_categories?.includes(167)
    let tidyTitle = title.replace(' LR',' <sup title="Long run">(LR)</sup>').replace(' SR',' <sup title="Sort run">(SR)</sup>')
    return (
      
        <GatsbyLink to={path} label={title} className="product-list-item">
          <div>
            {special && <div className="special-tag">Special</div>}
            <div className="image"><ImagePass src={image_to_use} className="background" /></div>
            <div className="inner">
              <h2 className="title" dangerouslySetInnerHTML={{__html:tidyTitle}} />
              {count && <p>{count} Style{count === 1 ? '' : 's' }</p>}
              <button className="product-link">{linkText}</button>
            </div>
          </div>
        </GatsbyLink>
      
    )
  }
}
