import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { camelCase, isBrowser } from '../../utils/helpers';
import './QuotePreview.scss'
import { Quote } from './index'
import { FaCircleNotch } from 'react-icons/fa';

export default class QuotePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      statusLoading: false,
      quoteId : null,
      quote: null,
      quoteStatus: 'New'
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    let quoteId = null
    if (isBrowser) {
      const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
      quoteId = urlParams && urlParams.get('quote');
      if (quoteId) { this.setState({ quoteId: quoteId }) }
    }
    if (quoteId) {
      const quoteFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/quote/v2/view`
      const appSecret = process.env.GATSBY_WEB_APP_SECRET
      fetch(quoteFunctionUrl, {
        method: 'POST',
        body: JSON.stringify({secret: appSecret, quoteId: quoteId})
      })
      .then(response => {

       if (response.status === 200) {
         response.json().then(json => {
           //console.log('json:',json)
           this.setState({
             loading: false,
             submitted: true,
             quote: json.quote,
             quoteNo: json.quoteNo,
             quoteDate: json.quoteDate,
             userName: json.userName,
             userState: json.userState,
             quoteStatus: json.quoteStatus ? json.quoteStatus.name : null
           });
         });

       } else {
         this.setState({ loading: false, error: true, quote: null });
       }
      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, quote: null });
      });
    } else {
      this.setState({ loading: false, error: true, quote: null });
    }
  }

  updateStatus = (status, attendantId) => {
    const quoteFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/quote/v2/status`
    const appSecret = process.env.GATSBY_WEB_APP_SECRET
    this.setState({ statusLoading: true });
    let quoteId = null
    if (isBrowser) {
      const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
      quoteId = urlParams && urlParams.get('quote');
      if (quoteId) { this.setState({ quoteId: quoteId }) }
    }
    if (quoteId) {
      fetch(quoteFunctionUrl, {
        method: 'POST',
        body: JSON.stringify({secret: appSecret, quoteId: quoteId, quoteStatus: status, attendantId: attendantId})
      })
      .then(response => {
  
       if (response.status === 200) {
        response.json().then(json => {
          this.setState({
            quoteStatus: json.response,
            statusLoading: false,
            submitted: true,
          })
        })
        
        console.log('Quote approved')
       } else {
        console.log('There was an error :(')
       }
      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ statusLoading: false, error: true, quote: null });
      });
    }
  }

  approveQuote = (e, attendantId) => {
    e.preventDefault()
    this.updateStatus('Approved', attendantId);
  }

  rejectQuote = (e, attendantId) => {
    e.preventDefault()
    this.updateStatus('Rejected', attendantId);
  }

  render() {
    const { loading, error, quote, quoteDate, quoteNo, userName, userState, quoteStatus, statusLoading } = this.state
    if (loading) { return <section className="quote-view"><div className="loading"><div><FaCircleNotch className="spin" /></div></div></section> }
    if (error) { return 'Error' }
    return (
      <section className="quote-view">
        <div className="wrap">
          {quote && <Quote quotePass={quote} quoteStatus={quoteStatus} statusLoading={statusLoading} quoteDate={quoteDate} quoteNo={quoteNo} userName={userName} userState={userState} rejectQuote={this.rejectQuote} approveQuote={this.approveQuote} />}
        </div>
      </section>
    )
  }
}
