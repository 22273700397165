import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from '../../Image'
import Carousel from '../../Carousel';
import { getUrlVars, isClient, compileQuery, slugify } from '../../../utils/helpers'
import './Gallery.scss';
import { FaTimes, FaChevronRight, FaChevronLeft } from 'react-icons/fa';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

class ThisGallery extends Component {

  state = {
    filter: 'all',
    openNavKey: null
  };

  nivigateTo(event, value) {
    this.setState({
      filter: value
    })
  }

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleModal(event);
  }

  toggleModal = (event, openNavKey) => {
    event.preventDefault();
    //console.log('openNavKey:',openNavKey)
    document.body.classList.remove('modal-open')
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    openNavKey && document.body.classList.add('modal-open')
    return this.setState({ openNavKey });
  }
  render () {
    const { urls, columns = 1, data } = this.props;
    const { openNavKey } = this.state
    const pageAttr = getUrlVars()
    const settings = {
      container:'gallery-carousel',
      nav: true,
      mouseDrag: true,
      items: 1,
      controls: true,
      controlsPosition:'bottom',
      navPosition:'bottom',
      autoplay:true,
    };

    const colCount = parseInt(columns.replace(/['"]+/g, '')) - 1
    const arrayNum = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight'];

    if (urls) {
      const urlArray = urls.replace(/['"]+/g, '').split(',');
      //console.log('urlArray:',urlArray)
      if (colCount == 1) {
        return (
          <>
            <div className="gallery-slider">
              <Carousel settings={settings}>
              {urlArray.map((iurl, index) => (
                  <div key={index} className="slide" style={{ position: "relative" }}>
                    <div className="inside">
                      <div className="open-modal" onClick={event => this.toggleModal(event, `${index}`)}>
                        <Image className="image" src={iurl} />
                      </div>
                    </div>
                  </div>
                )
              )}
              </Carousel>
            </div>
            <div className="slider-modal">
              {urlArray.map((iurl, index) => {
                return (
                  <div key={index}>
                  <div onClick={event => this.toggleModal(event, `${index}`)} className={`gallery-modal${openNavKey === `${index}` ? ' open' : ' closed'}`}>
                    <div className="modal-inner">
                      <div className="wrap">
                        <div className="title">Title</div>
                        <Image className="image" src={iurl} />
                        <FaTimes className="close"/>
                      </div>
                    </div>
                  </div>
                  <div className={`controls${openNavKey === `${index}` ? ' open' : ' closed'}`}>
                    {urlArray.length > index + 1 && <button className="next" onClick={event => this.toggleModal(event, `${index + 1}`)}><FaChevronRight/></button> }
                    {index > 0 && <button className="back" onClick={event => this.toggleModal(event, `${index - 1}`)}><FaChevronLeft/></button>}
                  </div>
                  </div>
                )
              })}
            </div>
          </>
        )
      } else {
        return (
        <div className="gallery-tiles">
          {urlArray.map((iurl, index) => (
              <div key={index} className={`tile ${arrayNum[colCount]}`}>
                <div className="inside">
                    <Image className="image" src={iurl} />
                </div>
              </div>
            )
          )}
        </div>
        )
      }
      
    }else{
      return null;
    }
  }
}


export const Gallery = (props) => {
  return (
    <ThisGallery {...props} />
  )
}
