import React, { Component } from "react"
import { FaCaretLeft } from 'react-icons/fa';
import ls from 'local-storage'
import { navigate } from 'gatsby';
import { Image } from '../../../Image';
import {filterByQty, priceByQty} from '../../helpers'
import { slugify, numberToMoney, truncated, isBrowser } from '../../../../utils/helpers';
import { AdminPrice } from '../../AdminPrice'

class ThisTransfer extends Component {
  state = {
    quote: ls.get('quote') ? ls.get('quote') : [],
    showPrice: ls.get('showPrice') ? true : false,
    qty: [],
    lineItem: [],
    calc: [],
    qtySet: null,
    quoteType: null,
    productGroup: null,
    note: '',
    totalNsw: 0,
    totalWa: 0,
    definition: 'SD',
    sound: '',
    feet:0,
    restore: 'restore'
  }

  componentDidMount() {
    //More work required here to pass the transfer type object to state
    const permitted = ['video-to-dvd', 'bleep']
    if (this.props?.productPass && permitted.includes(this.props.productPass)) {
      this.setState({quoteType: this.props.productPass}) 
    }
  }

  setProductGroup = (event, a, type) => {
    this.setState({
      productGroup: a,
      quoteType: type,
      product: null,
      packaging: null,
      extrasOne: null,
      extrasTwo: null,
      qtySet: null,
      qty: {},
      lineItem: {},
      calc:[],
      productFilter: null,
      definition: 'SD',
      sound: '',
      feet:0,
      restore: 'restore'
    }, () => {
      this.calcPrice()
    })
  }

  setQty = (event, a) => {
    this.setState({
      qtySet: true
    }, () => {
      this.calcPrice()
    })
  }

  onChangeHandler = (e) => {
    let newState = this.state
    //console.log('State:',this.state)
    if ( e.target.type === 'checkbox' ) {
      //Toggle value on off
      newState[e.target.name] === e.target.value ? newState[e.target.name] = '' : newState[e.target.name] = e.target.value
    } else {
      if (e.target.name.includes('qty-')) {
        //console.log('e.target:',e.target.title)
        newState['qty'][e.target.name] = e.target.value
        newState['lineItem'][e.target.name] = {qty: e.target.value, name:e.target.title}
      }else{
        newState[e.target.name] = e.target.value
      }
    }

    if ( e.target.name == 'sound' ) {
      newState['lineItem']['sound'] = {qty: '-', name: `Sound: ${this.state.sound === 'sound' ? 'Yes' : 'No'}`}
    }
    if ( e.target.name == 'definition' ) {
      newState['lineItem']['definition'] = {qty: '-', name: `Definition: ${this.state.definition}`}
    }

    //Film calc
    if (e.target.name.includes('calc-') ) {
      newState['lineItem']['totalFeet'] = {}
      newState['lineItem']['definition'] = {qty: '-', name: `Definition: ${this.state.definition}`}
      newState['lineItem']['sound'] = {qty: '-', name: `Sound: ${this.state.sound === 'sound' ? 'Yes' : 'No'}`}
      newState['lineItem'][e.target.name] = {qty: e.target.value, name:e.target.title}
      newState['calc'][e.target.name] = e.target.value
      this.setState(newState, () => {
        //Update qty
        let qtySum = 0
        for (let [key, thisQty] of Object.entries(this.state.calc)) {
          qtySum = qtySum + Number(thisQty)*Number(key.replace('calc-',''))
        }
        this.setState({feet: qtySum }, () => {
          this.calcPrice()
        })
        newState['lineItem']['totalFeet'] = { qty: '-',name: `Total: ${qtySum}ft`}
        //newState['lineItem']['definition'] = {qty: '-', name: `Definition: ${this.state.definition}`}
        //newState['lineItem']['sound'] = {qty: '-', name: `Sound: ${this.state.sound === 'sound' ? 'Yes' : 'No'}`}
      })
    }

    this.setState(newState, () => {
      this.calcPrice()
    })
  }

  handleSubmit = ( e ) => {
    e.preventDefault()
    const {
      quote,
      note,
      totalNsw,
      totalWa,
      quoteType,
      lineItem,
      productGroup
    } = this.state
    const quoteProductArray = {
      note: note,
      totalWa: totalWa,
      totalWaIncGst: totalWa * 1.1,
      totalNsw: totalNsw,
      totalNswIncGst: totalWa * 1.1,
      quoteType: quoteType,
      lineItem: lineItem,
      productGroup: productGroup
    }
    const newArray = quote.concat(quoteProductArray)
    ls.set('quote', newArray)
    navigate('/request-a-quote/')
  }

  calcPrice() {
    const { products, markUp } = this.props
    const { qty, quoteType, definition, sound } = this.state

    let unitPrice = 0
    let totalWa = 0
    let totalNsw = 0
    let markupWaPercent = 1
    let markupNswPercent = 1
    let qtySum = 0

    if (['video-to-dvd','audio-transfer','scanning'].includes(quoteType)) {
      //Video Transfers
      for (let [key, thisQty] of Object.entries(qty)) {
        qtySum = qtySum + Number(thisQty)
      }

      for (let [key, thisQty] of Object.entries(qty)) {
        //Use the qty state and loop each key and find the related product price by this qty
        const productFilter = products.filter(a => a.node.slug === key.replace('qty-',''))
        const product = productFilter && productFilter[0].node
        const lastProductPriceByQty = product && qtySum && product.acf.productPrice.filter(a => priceByQty(a, thisQty)).pop()

        if (product && product.mark_up && product.mark_up[0]) {
          const markupGroup = markUp.filter(a => a.node.wordpress_id === product.mark_up[0])
          const markupWaPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpWa.filter(a => priceByQty(a, qtySum)).pop()
          const markupNswPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpNsw.filter(a => priceByQty(a, qtySum)).pop()
          markupWaPercent = markupWaPass ? markupWaPass.percent : markupWaPercent
          markupNswPercent = markupNswPass ? markupNswPass.percent : markupNswPercent
        }

        if (lastProductPriceByQty && lastProductPriceByQty.price )  {
          unitPrice = unitPrice + (Number(lastProductPriceByQty.price) * Number(thisQty))
        }
      }
      totalWa = unitPrice * markupWaPercent
      totalNsw = unitPrice * markupNswPercent
    }

    if (quoteType === 'audio-transfer') {
      if (this.state.restore !== 'restore') {
        const audioTransfer = products.filter(a => a.node.slug === `audio-unrestored`)
        const product = audioTransfer && audioTransfer[0].node
        const lastProductPriceByQty = product && qtySum && product.acf.productPrice.filter(a => priceByQty(a, Number(qtySum))).pop()
        unitPrice = (Number(lastProductPriceByQty.price) * Number(qtySum))
      }
      totalWa = unitPrice * markupWaPercent
      totalNsw = unitPrice * markupNswPercent
    }

    if (quoteType === 'film') {
      const compileSlug = `${slugify(definition)}-film-transfers${sound ? '' : '-silent'}`
      const productFilter = products.filter(a => a.node.slug === compileSlug)
      const product = productFilter && productFilter[0].node
      //console.log('product:',product)
      if (product && product.mark_up && product.mark_up[0]) {
        const markupGroup = markUp.filter(a => a.node.wordpress_id === product.mark_up[0])
        const markupWaPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpWa.filter(a => priceByQty(a, this.state.feet)).pop()
        const markupNswPass = markupGroup[0].node.acf && markupGroup[0].node.acf.markUpNsw.filter(a => priceByQty(a, this.state.feet)).pop()
        markupWaPercent = markupWaPass ? markupWaPass.percent : markupWaPercent
        markupNswPercent = markupNswPass ? markupNswPass.percent : markupNswPercent
      }

      const lastProductPriceByQty = product && this.state.feet && product.acf.productPrice.filter(a => priceByQty(a, Number(this.state.feet))).pop()
      if (lastProductPriceByQty && lastProductPriceByQty.price )  {
        unitPrice = unitPrice + (Number(lastProductPriceByQty.price) * Number(this.state.feet))
      }
      //Film transfer min price after markup

      totalWa = unitPrice * markupWaPercent
      totalNsw = unitPrice * markupNswPercent

      if (definition === 'SD') {
        if (totalWa < 50*0.909090909090) { totalWa = 50*0.909090909090 }
        if (totalNsw < 50*0.909090909090) { totalNsw = 50*0.909090909090 }
      } else {
        if (totalWa < 75*0.909090909090) { totalWa = 75*0.909090909090 }
        if (totalNsw < 75*0.909090909090) { totalNsw = 75*0.909090909090 }
      }
    }

    if (quoteType === 'scanning') {
      if (this.state.slideShow && this.state.slideShow !== 'none') {
        const slideShow = products.filter(a => a.node.slug === `scanning-${this.state.slideShow}`)
        const product = slideShow && slideShow[0].node
        const lastProductPriceByQty = product && qtySum && product.acf.productPrice.filter(a => priceByQty(a, Number(qtySum))).pop()
        unitPrice = unitPrice + Number(lastProductPriceByQty.price)
      }
      //Scannint min price after markup
      totalWa = unitPrice * markupWaPercent
      totalNsw = unitPrice * markupNswPercent
      
      if (totalWa < 20*0.909090909090) { totalWa = 20*0.909090909090 }
      if (totalNsw < 20*0.909090909090) { totalNsw = 20*0.909090909090 }
    }

    this.setState({
      totalWa:(totalWa),
      totalNsw: (totalNsw),
    })
  }

  render() {
    const {
      products,
      categories,
      parent,
      setQuoteType
    } = this.props

    const {
      qty,
      productGroup,
      quoteType,
      customQuote,
      totalWa,
      totalNsw,
      showPrice,
      note
    } = this.state

    if ( productGroup ) { //After product group has been selected
      const thisProducts = products.filter(a => a.node.product_categories.includes(productGroup.wordpress_id))

      return (
        <div className="content transfers">

          <div className="tile-title">
              <span className="title">{productGroup.acf.displayTitle ? productGroup.acf.displayTitle : productGroup.name} <strong>Transfers</strong></span>
              <button className="back-button" onClick={event => this.setProductGroup(event, null)}><FaCaretLeft/> Change Transfer Type</button>
          </div>

          <div className="options-group form">
            <div className="wrap form-inner">
                <h4 className="group-title">Select media quantity</h4>
                {['video-to-dvd','audio-transfer','scanning'].includes(quoteType) && //Video, audio and photo scanning quantity
                  <>
                    {thisProducts && thisProducts.map((a, index) => {
                      return (

                        <div key={index} className={`item inline${this.state['qty'][`qty-${a.node.slug}`] ? ' active' : ''}`}>
                          <div className="description">{a.node.acf.displayTitle ? a.node.acf.displayTitle : a.node.title}</div>
                          <div className="inner">
                            <label htmlFor={`qty-${a.node.slug}`}>{a.node.acf.displayTitle ? a.node.acf.displayTitle : a.node.title}</label>
                            <input
                              name={`qty-${a.node.slug}`}
                              id={`qty-${a.node.slug}`}
                              placeholder="Qty"
                              type="number"
                              pattern="[0-9]*"
                              title={a.node.acf.displayTitle ? a.node.acf.displayTitle : a.node.title}
                              min={productGroup.acf.minQty ? productGroup.acf.minQty : 0}
                              max={productGroup.acf.maxQty ? productGroup.acf.maxQty : 1000}
                              value={this.state['qty'][`qty-${a.node.slug}`] ? this.state[`qty-${a.node.slug}`] : ''}
                              onChange={e => this.onChangeHandler(e)}
                            />
                          </div>
                        </div>

                      )
                    })}

                    {/*quoteType ==='scanning' && //Scanning
                    <>
                      <h4 className="group-title">What type of slide show would you like?<a href="/transfers/slides-negatives-photos/" target="_blank" className="info-button">?</a></h4>
                      
                      <div className="item half active">
                        <label htmlFor="SlideShow">Slide show</label>
                        <div className="select">
                          <select name="slideShow" id="slideShow" onChange={e => this.onChangeHandler(e)}>
                            <option value="none">No slide show</option>
                            <option value="basic">Basic slide show</option>
                            <option value="ultimate">Ultimate slide show</option>
                          </select>
                        </div>
                      </div>
                    </>
                    */}
                    <input type="hidden" name="restore" id="restore" value={this.state.restore === 'restore'} />
                    {/*quoteType ==='audio-transfer' && //Audio transfers set value as default
                    <>
                      <h4 className="group-title">Would you like your audio restored? (EQ correction)<span>White noise and blank sections removed.</span> <a href="/transfers/audio-to-cd/" target="_blank" className="info-button">?</a></h4>
                      <div className="item">

                          <label htmlFor="resotre" className="checkbox">Would you like your audio restored? (EQ correction)<span>White noise and blank sections removed.</span></label>
                          <div className="input-group">
                          <input name="restore" id="restore" checked={this.state.restore === 'restore'} type="checkbox" onChange={e => this.onChangeHandler(e)} value='restore' disabled  />
                            <span className="description">Yes</span>
                          </div>

                      </div>
                    </>
                    */}
                  </>
                }

                {quoteType == 'film' && //Film transfers
                <>
                  <div className={`item inline${this.state['calc']['calc-50'] ? ' active' : '' }`}>
                    <div className="description">3 inch reels</div>
                    <div className="inner">
                      <label htmlFor="calc-50">3 inch reels</label>
                      <input
                        name="calc-50"
                        id="calc-50"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Qty"
                        min="0"
                        max="500"
                        title="3 inch reels"
                        value={this.state['calc']['calc-50'] ? this.state['calc']['calc-50'] : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={`item inline${this.state['calc']['calc-100'] ? ' active' : ''}`}>
                    <div className="description">4 inch reels</div>
                    <div className="inner">
                      <label htmlFor="calc-100">4 inch reels</label>
                      <input
                        name="calc-100"
                        id="calc-100"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Qty"
                        min="0"
                        max="500"
                        title="4 inch reels"
                        value={this.state['calc']['calc-100'] ? this.state['calc']['calc-100'] : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={`item inline${this.state['calc']['calc-200'] ? ' active': ''}`}>
                    <div className="description">5 inch reels</div>
                    <div className="inner">
                      <label htmlFor="calc-200">5 inch reels</label>
                      <input
                        name="calc-200"
                        id="calc-200"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Qty"
                        min="0"
                        max="500"
                        title="5 inch reels"
                        value={this.state['calc']['calc-200'] ? this.state['calc']['calc-200'] : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={`item inline${this.state['calc']['calc-300'] ? ' active' : ''}`}>
                    <div className="description">6 inch reels</div>
                    <div className="inner">
                      <label htmlFor="calc-300">6 inch reels</label>
                      <input
                        name="calc-300"
                        id="calc-300"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Qty"
                        min="0"
                        max="500"
                        title="6 inch reels"
                        value={this.state['calc']['calc-300'] ? this.state['calc']['calc-300'] : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div className={`item inline${this.state['calc']['calc-400'] ? ' active': ''}`}>
                    <div className="description">7 inch reels</div>
                    <div className="inner">
                      <label htmlFor="calc-400">7 inch reels</label>
                      <input
                        name="calc-400"
                        id="calc-400"
                        type="number"
                        placeholder="Qty"
                        min="0"
                        max="500"
                        title="7 inch reels"
                        value={this.state['calc']['calc-400'] ? this.state['calc']['calc-400'] : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>

                  <div className="item inline or">
                    <div className="description">or</div>
                  </div>

                  <div className={`item inline${this.state.feet ? ' active' : '' }`}>
                    <div className="description">How many feet of film do you have? <a href="/transfers/8mm-super8-16mm-film-to-dvd/" target="_blank" className="info-button">?</a></div>
                    <div className="inner">
                      <label htmlFor="feet">ft</label>
                      <input
                        name="feet"
                        id="feet"
                        type="number"
                        pattern="[0-9]*"
                        placeholder="Qty"
                        min="0"
                        max="10000"
                        title="Feet"
                        value={this.state.feet ? this.state.feet : ''}
                        onChange={e => this.onChangeHandler(e)}
                      />
                    </div>
                  </div>

                  <h4 className="group-title">Select your transfer quality <a href="/transfers/8mm-super8-16mm-film-to-dvd/hd-vs-sd/" target="_blank" className="info-button">?</a></h4>

                  <div className="item">
                    <label htmlFor="definition">Definition</label>
                    <div className="input-group">
                      <input name="definition" type="radio" title="HD" checked={this.state.definition === 'HD'} onChange={e => this.onChangeHandler(e)} value="HD" />
                      <span className="description">HIGH DEFINITION (HD)</span>
                      <input name="definition" type="radio" title="SD" checked={this.state.definition === 'SD'} onChange={e => this.onChangeHandler(e)} value="SD" />
                      <span className="description">STANDARD DEFINITION (SD)</span>
                      <input name="definition" type="radio" title="2K" checked={this.state.definition === '2K'} onChange={e => this.onChangeHandler(e)} value="2K" />
                      <span className="description">2K DEFINITION</span>
                    </div>
                  </div>

                  <h4 className="group-title">Does your film have sound?</h4>

                  <div className="item">
                    <label htmlFor="sound" className="checkbox">Does your film have sound?</label>
                    <div className="input-group">
                      <input name="sound" title="Sound" id="sound" checked={this.state.sound === 'sound'} type="checkbox" onChange={e => this.onChangeHandler(e)} value="sound"  />
                      <span className="description">Yes</span>
                    </div>
                  </div>
                </>
                }


                  <>
                    <h4 className="group-title">Add product instructions / notes</h4>
                    <div className={`note${note ? ' active' : '' }`}>
                      <label htmlFor="note">Add a note</label>
                      <textarea name="note" id="note" placeholder="Add a note" onChange={e => this.onChangeHandler(e)} value={note} />
                    </div>

                    <div className="add-to-cart">
                      <div className="inner">
                        <button className={`add-to-quote${totalWa > 1 ? '' : ' disable'}`} onClick={e => this.handleSubmit(e)} disabled={totalWa > 1 ? false : true}>Add to quote</button>
                      </div>
                    </div>
                  </>
 
            </div>
          </div>

          {showPrice && <AdminPrice totalWa={totalWa} totalNsw={totalNsw} qty={qty} customQuote={customQuote} />}

        </div>
      )
    }

    const parentCategories = categories.filter(a => a.node.wordpress_parent === parent.wordpress_id) //Top level options

    return (
      <div className="content transfers">

        <div className="tile-title">
          <span className="title"><strong>Select transfer type</strong></span>
          <button className="back-button" onClick={event => setQuoteType(event, null)}><FaCaretLeft/> Change Quote Type</button>
        </div>

        <div className="groups">
          <div className="product-group">
            <div className="wrap">
              {parentCategories && parentCategories.map((a, index)=> {
                return (
                  <div className="product" key={index} onClick={event => this.setProductGroup(event, a.node, a.node.slug)}>
                    {a.node.acf.featured_image && <div className="icon"><Image src={a.node.acf.featured_image}/></div>}
                    <h3 className="title">{a.node.acf && a.node.acf.displayTitle ? a.node.acf.displayTitle : a.node.name}</h3>
                    <button className="action">Get started</button>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const Transfers = (props) => {
  return <ThisTransfer {...props} />
}
